import { Breadcrumbs } from "components/breadcrumbs";
import { Title } from "components/title";
import { useLanguage } from "components/language-provider";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Variables } from "services/variables";
import { useCurrentNew } from "views/landing/news/hooks/use-current-new";
import { OtherNews } from "./other-news";
import {
    MainImg,
    NewsContent,
    NewsDataTime,
    NewsText,
    OneNewsSC,
} from "./styled";
import { Helmet } from "@webx/react-helmet-with-visor";
import { ErrorText } from "components/conditional-list";
import { LoadingText } from "components/no-data";

interface IInjectedProps {
    match: {
        params: {
            name: string;
        };
    };
}

interface IProps extends IInjectedProps {}

export const CurrentNews = withRouter(
    React.memo((props: IProps) => {
        const [__] = useTranslation();
        const { language } = useLanguage();
        const { match } = props;
        const { loading, error, currentNew } = useCurrentNew(
            language.code,
            match.params.name,
        );

        if (error) {
            return <ErrorText>{__("При запросе произошла ошибка")}</ErrorText>;
        }

        return loading || !currentNew ? (
            <LoadingText>{__("Загрузка")}</LoadingText>
        ) : (
            <>
                <Helmet>
                    {currentNew.metaTitle && (
                        <title>{currentNew.metaTitle}</title>
                    )}
                    {currentNew.metaDescription && (
                        <meta
                            name="description"
                            content={currentNew.metaDescription}
                        />
                    )}
                </Helmet>
                <OneNewsSC>
                    <NewsContent>
                        <Breadcrumbs
                            params={match.params.name}
                            title={currentNew.title}
                        />
                        {currentNew.image && (
                            <MainImg
                                src={`${Variables.fileStoragePath}/${currentNew.image}`}
                                alt="news_preview_image"
                                role="presentation"
                            />
                        )}
                        <NewsDataTime>
                            {moment(currentNew.datePublick)
                                .locale(language.code)
                                .format("L")}
                        </NewsDataTime>
                        <NewsText>
                            <Title>{currentNew.title}</Title>
                            {currentNew.mainText && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        __html: currentNew.mainText,
                                    }}
                                />
                            )}
                        </NewsText>
                    </NewsContent>
                    <OtherNews params={match.params.name} />
                </OneNewsSC>
            </>
        );
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
