import React from "react";
import { Helmet } from "@webx/react-helmet-with-visor";
import { useTranslation } from "react-i18next";
import { WrapperFullWidth, Wrapper, InfoPageWrapper } from "styled/layouts";
import { PrivacyPolicyInformation } from "./privacy-policy-information";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { _1XBET_PARTNERS_IRELAND } from "server/partner-programs";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const PrivacyPolicy = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const {
        partnersProgramId,
        agentSiteInformation: { companyName },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    let title = __("Политика конфиденциальности");
    let description = "";
    let h1 = "";

    if (partnersProgramId === _1XBET_PARTNERS_IRELAND) {
        title = format(
            __("Политика конфиденциальности партнеров {companyName}"),
            { companyName },
        );
        description = format(
            __(
                "Политика конфиденциальности по партнерской программе {companyName}",
            ),
            { companyName },
        );
        h1 = __("Политика конфиденциальности");
    }

    return (
        <WrapperFullWidth>
            <Helmet>
                <title>{title}</title>
                {description && (
                    <meta name="description" content={description} />
                )}
            </Helmet>
            <Wrapper>
                <InfoPageWrapper>
                    {h1 && <h1>{h1}</h1>}
                    <PrivacyPolicyInformation />
                </InfoPageWrapper>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
