import { PageRoute } from "components/check-roles/guard";
import { Footer } from "components/footer";
import { HeaderLanding } from "components/header-landing";
import { NotFoundHandler } from "components/not-found-handler";
import urljoin from "url-join";
import { Contacts } from "../contacts";
import { CookiesPolicy } from "../cookies-policy";
import { Home } from "../home";
import { Home as BetAndYouHome } from "../home/betandyou-home";
import { Home as PartnersMelbetRu } from "../home/partnersmelbetru-home";
import { Home as CasinoPartners } from "../home/1xcasino-home";
import { News } from "../news";
import { Faq } from "../faq";
import { PrivacyPolicy } from "../privacy-policy";
import { Terms } from "../terms";
import { ContentColumn, Main } from "styled/layouts";
import { Switch } from "react-router-dom";
import { ScrollToTop } from "components/scroll-to-top";
import { connect } from "react-redux";
import { statesSelectors } from "services/redux/root/states/selectors";
import { RouteComponentProps } from "react-router-dom";
import { useDefinedContext } from "hooks/use-context-exist";
import { ResetPasswordForm } from "../../auth/reset-password-form";
import { SignInPage } from "../../auth/sign-in-form";
import { SignUp } from "../../auth/sign-up";
import { SignUpComplete } from "../../auth/sign-up/thanks";
import { DefaultNewPasswordForm } from "../../auth/new-password";
import { Unsubscribe } from "../../landing/unsubscribe";
import { ThemeProvider } from "components/theme/theme-provider";
import { PartnersProgramGeneralInformationContext } from "views";
import {
    BETANDYOU_AFFILIATES,
    PARTNERS_MELBETRU,
    COINPLAY_PARTNERS,
    _1XCASINO_PARTNERS,
    _888STARZ_PARTNERS,
    _1XBET_PARTNERS_IRELAND,
} from "server/partner-programs";
import { RegularGlobalStyles } from "services/styled-components/regular-global-styles";
import { Access } from "components/check-roles/access";
import { CookiesAgreementToast } from "components/components-common/cookies-agreement-toast";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks/query/use-user";
import { Utils } from "@webx/utils";

interface IInjectedProps extends RouteComponentProps {
    isMenuBurgerClickedOnLanding: boolean;
}

interface IProps extends IInjectedProps {
    url: string;
    search?: string;
}

type IInitialProps = Subtract<IProps, IInjectedProps>;

export type { IInitialProps as IRegularLandingProps };

export const HomeRedirect = () => {
    if (Utils.browser.hasWindow()) {
        document.location.reload();
    }

    return <></>;
};

const RegularLanding = connect(state => ({
    isMenuBurgerClickedOnLanding:
        statesSelectors.getIsMenuBurgerClickedOnLanding(state),
}))(({ url, isMenuBurgerClickedOnLanding, search }: IProps) => {
    const {
        visibility: { showingNews },
        partnersProgramId,
        partnerProgramCompanyName,
        agentSiteInformation: { companyName },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);
    const [__] = useTranslation();
    const { user } = useUser();

    let titleText;
    let metaText;
    if (partnersProgramId === COINPLAY_PARTNERS) {
        titleText =
            format(
                __(
                    "Партнеры {companyName} | Криптоказино с крупной прибылью и букмекерская партнерская программа",
                ),
                { companyName },
            ) + "!";
        metaText =
            format(
                __(
                    "Криптоказино {companyName} и букмекерская партнерская программа. Зарегистрируйтесь прямо сейчас и получите до 40% по программе RevShare!. {separator}✅Разные модели получения прибыли: RevShare/CPA/Hybrid {separator}✅Быстрый вывод средств {separator}✅Выгодные условия для партнеров {separator}✅Мгновенная поддержка",
                ),
                { companyName, separator: "\n" },
            ) + "!";
    }
    if (partnersProgramId === _1XBET_PARTNERS_IRELAND) {
        titleText = format(
            __(
                "Зарабатывайте с партнерами {companyName} в Ирландии | Станьте партнером {companyName}",
            ),
            { companyName },
        );
        metaText = format(
            __(
                "Партнерская программа {partnerProgramCompanyName}. Зарабатывайте до 50 % на ставках на спорт с {companyName} по партнерской программе.",
            ),
            { companyName, partnerProgramCompanyName },
        );
    }
    let urlRedirect = "sign-in";
    if (search) {
        urlRedirect = `sign-in${search}`;
    }

    return (
        <ThemeProvider>
            <RootHelmetAndScriptsBodyInjector
                titleText={titleText}
                metaText={metaText}
            />
            <RegularGlobalStyles />
            <ScrollToTop>
                <ContentColumn
                    isMenuBurgerClickedOnLanding={isMenuBurgerClickedOnLanding}
                >
                    <HeaderLanding />
                    <Main>
                        <Switch>
                            <PageRoute
                                path={urljoin(url)}
                                exact={true}
                                component={() => {
                                    switch (partnersProgramId) {
                                        case BETANDYOU_AFFILIATES:
                                            return <BetAndYouHome />;
                                        case PARTNERS_MELBETRU:
                                            return <PartnersMelbetRu />;
                                        case _1XCASINO_PARTNERS:
                                            return <CasinoPartners />;
                                        case _888STARZ_PARTNERS:
                                            return <HomeRedirect />;
                                        default:
                                            return <Home />;
                                    }
                                }}
                                redirect={urlRedirect}
                            />
                            {showingNews.showNews && (
                                <PageRoute
                                    exact={false}
                                    path={urljoin(url, "news")}
                                    component={News}
                                />
                            )}
                            <PageRoute
                                path={urljoin(url, "contacts")}
                                component={Contacts}
                            />
                            <PageRoute
                                path={urljoin(url, "terms")}
                                component={Terms}
                            />
                            <PageRoute
                                path={urljoin(url, "privacy")}
                                component={PrivacyPolicy}
                            />
                            <PageRoute
                                path={urljoin(url, "faq")}
                                component={Faq}
                            />
                            <PageRoute
                                path={urljoin(url, "cookies")}
                                component={CookiesPolicy}
                            />
                            <PageRoute
                                path={urljoin(
                                    url,
                                    "sign-in",
                                    "partner",
                                    ":partnerId",
                                )}
                                component={SignInPage}
                            />
                            <PageRoute
                                path={urljoin(url, "sign-in")}
                                component={SignInPage}
                            />
                            {!user && (
                                <PageRoute
                                    path={urljoin(url, "sign-up")}
                                    component={SignUp}
                                />
                            )}
                            {!user && (
                                <PageRoute
                                    path={urljoin(url, "sign-up-complete")}
                                    component={SignUpComplete}
                                />
                            )}
                            {!user && (
                                <PageRoute
                                    path={urljoin(url, "reset-password")}
                                    component={ResetPasswordForm}
                                />
                            )}

                            <PageRoute
                                path={urljoin(url, "changepassword")}
                                component={DefaultNewPasswordForm}
                            />

                            <PageRoute
                                path={urljoin(url, "unsubscribe/:oneOffToken")}
                                component={Unsubscribe}
                                exact={false}
                            />
                            <PageRoute component={NotFoundHandler} path="*" />
                        </Switch>
                    </Main>
                    <Footer />
                </ContentColumn>
            </ScrollToTop>
            <ToastContainer position="top-center" newestOnTop={true} />
            <Access name="cookies">
                <CookiesAgreementToast />
            </Access>
        </ThemeProvider>
    );
}) as React.ComponentType<IInitialProps>;

export { RegularLanding as Landing };
