import React from "react";
import { Helmet } from "@webx/react-helmet-with-visor";
import { useTranslation } from "react-i18next";
import { WrapperFullWidth, Wrapper, InfoPageWrapper } from "styled/layouts";
import { TermsAndConditions } from "./terms-and-conditions";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { WINWIN_PARTNERS } from "server/partner-programs";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const Terms = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const {
        partnersProgramId,
        partnerProgramCompanyName,
        agentSiteInformation,
        siteAddress,
    } = useDefinedContext(PartnersProgramGeneralInformationContext);
    let title = format(
        __(
            "Правила и условия использования сайта | {PartnerCompanyName} | Партнерская программа {CompanyName}",
        ),
        {
            PartnerCompanyName: partnerProgramCompanyName,
            CompanyName: agentSiteInformation.companyName,
        },
    );
    let description = format(
        __(
            "Эта страница устанавливает условия и правила доступа к Сайту, в том числе: условия использования материалов, размещенных на сайте {SiteAddress}",
        ),
        {
            SiteAddress: siteAddress,
        },
    );

    if (partnersProgramId === WINWIN_PARTNERS) {
        title = format(
            __("Условия партнерской программы - {PartnerCompanyName}"),
            {
                PartnerCompanyName: partnerProgramCompanyName,
            },
        );
        description = format(
            __(
                "Изучите условия партнерской программы {PartnerCompanyName}! Повышайте свои доходы с нашей поддержкой. Присоединяйтесь сегодня!",
            ),
            {
                PartnerCompanyName: partnerProgramCompanyName,
            },
        );
    }

    return (
        <WrapperFullWidth>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <Wrapper>
                <InfoPageWrapper>
                    <TermsAndConditions />
                </InfoPageWrapper>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
