import React from "react";
import { Helmet } from "@webx/react-helmet-with-visor";
import { useTranslation } from "react-i18next";
import { WrapperFullWidth, InfoPageWrapper, Wrapper } from "styled/layouts";
import { useCookiesPolicy } from "hooks/query/use-cookies-policy";
import { ContentLoader } from "components/components-common/content-loader";
import { List } from "react-content-loader";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const CookiesPolicy = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { policy, loading } = useCookiesPolicy();
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <WrapperFullWidth>
            <Helmet>
                <title>
                    {format(
                        __(
                            "COOKIES | {PartnerCompanyName} | Партнерская программа {CompanyName}",
                        ),
                        {
                            PartnerCompanyName:
                                partnersGeneralInformation.partnerProgramCompanyName,
                            CompanyName:
                                partnersGeneralInformation.agentSiteInformation
                                    .companyName,
                        },
                    )}
                </title>
                <meta
                    name="description"
                    content={format(
                        __(
                            "Данное уведомление касается информации, которую собирает и использует сайт {SiteAddress} при использовании веб-сайта.",
                        ),
                        {
                            SiteAddress: partnersGeneralInformation.siteAddress,
                        },
                    )}
                />
            </Helmet>
            <Wrapper>
                <InfoPageWrapper>
                    <ContentLoader
                        component={List}
                        times={10}
                        loading={loading}
                        text={policy}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                // eslint-disable-next-line @typescript-eslint/naming-convention
                                __html: policy,
                            }}
                        />
                    </ContentLoader>
                </InfoPageWrapper>
            </Wrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
