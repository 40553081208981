import { User } from "@styled-icons/fa-solid/User/User";
import { Email } from "@styled-icons/material/Email/Email";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { Field, Form } from "components/formik";
import React from "react";
import { Helmet } from "@webx/react-helmet-with-visor";
import { useTranslation } from "react-i18next";
import {
    FormDirectionColumn,
    FormGroup,
    FormGroupButton,
} from "styled/layouts";
import { AuthLayout } from "../auth-layout";
import { SignIn } from "../sign-in-form/styled";
import { useResetPassword } from "hooks/reset-password/use-reset-password";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { DefaultTheme, StyledComponent } from "styled-components";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { _1XBET_PARTNERS_IRELAND } from "server/partner-programs";

const initialValues = { login: "", email: "" };

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    signBox?: StyledComponent<"div", DefaultTheme>;
}

export const ResetPasswordForm = React.memo(({ signBox }: IProps) => {
    const [__] = useTranslation();
    const reset = useResetPassword();
    const {
        partnersProgramId,
        agentSiteInformation: { companyName },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    let description = "";

    if (partnersProgramId === _1XBET_PARTNERS_IRELAND) {
        description = format(
            __("Восстановление пароля для партнеров {companyName}"),
            { companyName },
        );
    }

    return (
        <AuthLayout signBox={signBox}>
            <Helmet>
                <title>{__("Восстановление пароля")}</title>
                {description && (
                    <meta name="description" content={description} />
                )}
            </Helmet>
            <SignIn>
                <FormikConsoleErrors
                    initialValues={initialValues}
                    onSubmit={reset.onSubmit}
                    validationSchema={reset.validationSchema}
                >
                    {() => (
                        <Form>
                            <FormDirectionColumn>
                                <FormGroup>
                                    <User size={18} />
                                    <Field
                                        name="login"
                                        type="text"
                                        placeholder={__("Имя пользователя")}
                                        autoComplete="on"
                                        autoFocus={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Email size={18} />
                                    <Field
                                        name="email"
                                        type="text"
                                        placeholder={__("Email")}
                                        autoComplete="on"
                                    />
                                </FormGroup>
                                {!reset.resetPassResponse.message && (
                                    <FormGroupButton>
                                        <SubmitButton>
                                            {__("Сбросить пароль")}
                                        </SubmitButton>
                                    </FormGroupButton>
                                )}
                            </FormDirectionColumn>
                        </Form>
                    )}
                </FormikConsoleErrors>
            </SignIn>
        </AuthLayout>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
